import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Projects from "../components/Projects"
// ...GatsbyImageSharpFluid

const ProjectsPage = () => {
  return <div>
    <h2>Proyectos y participaciones</h2>
    <p>Listado de side projects y de participaciones online.</p>
  </div>
}

export default ProjectsPage
